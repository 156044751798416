import { ImgixParameters, makeImgixUrl } from '@awdis/components'
import * as React from 'react'
import Helmet from 'react-helmet'

type Props = {
  pageUrl?: string
  type?: string
  title?: string
  description?: string
  image?: string
  imageParameters?: ImgixParameters
}

export default ({
  pageUrl,
  type,
  title,
  description,
  image,
  imageParameters: { crop = '', ...imageParameters } = {},
}: Props) => {
  const mergedParameters = {
    // Crop the image to the twitter dimensions
    fit: 'crop',
    w: 1080,
    h: 1080,
    crop: crop ? (['top'] as Array<string | number>).concat(crop) : 'top', // Default to top for cropping
    ...imageParameters,
  }

  const imgixUrl = makeImgixUrl(image, mergedParameters)
  return (
    <Helmet>
      {pageUrl && <meta property="og:url" content={pageUrl} />}
      {type && <meta property="og:type" content={type} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={imgixUrl} />}
    </Helmet>
  )
}
