import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import { linkButtonStyle } from '../LinkButton'

const RouterLinkButton = styled(
  ({ fill, basic, nohover, active, ...props }) => <Link {...props} />
)`
  ${linkButtonStyle};
`

export default RouterLinkButton
