import { ImgixParameters, makeImgixUrl } from '@awdis/components'
import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import Helmet from 'react-helmet'
import Facebook from './components/Facebook'
import Twitter from './components/Twitter'

type Props = {
  title?: string
  description?: string
  image?: string
  pathname?: string
  type?: string
  imageParameters?: ImgixParameters
}

const Seo = ({
  title,
  description,
  image,
  pathname,
  type = 'website',
  imageParameters = {},
}: Props) => (
  <StaticQuery
    query={graphql`
      query SEOQuery {
        site {
          siteMetadata {
            defaultTitle
            titleTemplate
            defaultDescription
            siteUrl
            defaultImage
            twitterUsername
            authorUrl
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
          authorUrl,
        },
      },
    }) => {
      let imgixImage

      if (image && image.includes('imgix')) {
        const mergedParameters = {
          // Crop the image to the twitter dimensions
          fit: 'crop',
          w: 1080,
          h: 1080,
          ...imageParameters,
          // Default to top for cropping
          crop: imageParameters.crop
            ? (['top'] as Array<string | number>).concat(imageParameters.crop)
            : 'top',
        }

        imgixImage = makeImgixUrl(image, mergedParameters)
      }

      const seo = {
        title,
        type,
        description: description || defaultDescription,
        image: image || defaultImage,
        url: `${siteUrl}${
          pathname && pathname.startsWith('/')
            ? pathname
            : `/${pathname}` || '/'
        }`,
      }

      return (
        <>
          <Helmet
            title={seo.title}
            titleTemplate={titleTemplate}
            defaultTitle={defaultTitle}
          >
            <meta charSet="utf-8" />
            <html lang="en" />
            <link rel="author" href={authorUrl} />
            <meta name="description" content={seo.description} />
            <meta name="image" content={imgixImage || seo.image} />
          </Helmet>
          <Facebook
            pageUrl={seo.url}
            title={seo.title}
            description={seo.description}
            image={seo.image}
            type={seo.type}
            imageParameters={imageParameters}
          />
          <Twitter
            username={twitterUsername}
            title={seo.title}
            description={seo.description}
            image={seo.image}
            imageParameters={imageParameters}
          />
        </>
      )
    }}
  />
)

export default Seo
