import * as React from 'react'
import styled from 'styled-components'
import { Title, properties } from '@awdis/components'

import Layout, { LayoutData } from '../../components/Layout'
import Seo from '../../components/Seo'
import ContactRedirectItem from '../../components/ContactRedirectItem'

const { ResponsiveBreakpoints } = properties

const RedirectItems = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  padding-top: 32px;
  padding-bottom: 48px;

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    grid-template-columns: 1fr 1fr;
  }
`

type ContactIndividualQuantityProps = {
  pageContext: {
    layoutData: LayoutData
    page: {
      title: string
      url: string
    }
  }
}

const defaultProps = {}

const ContactIndividualQuantity = function ContactIndividualQuantity({
  pageContext: {
    layoutData,
    layoutData: { brandDescription },
    page,
  },
}: ContactIndividualQuantityProps) {
  return (
    <Layout data={layoutData}>
      <Seo
        title={page.title}
        description={brandDescription}
        type="website"
        pathname={page.url}
      />
      <Title as="h2" size="small" align="center">
        How many items do you need?
      </Title>

      <RedirectItems>
        <ContactRedirectItem
          title="I need less than 20 items"
          buttonText="Select"
          redirectTo="/contact/individual"
        />

        <ContactRedirectItem
          title="I need more than 20 items"
          buttonText="Select"
          redirectTo="/contact/embroiderer"
        />
      </RedirectItems>
    </Layout>
  )
}

ContactIndividualQuantity.defaultProps = defaultProps

export default ContactIndividualQuantity
