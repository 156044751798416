import * as React from 'react'
import styled from 'styled-components'
import RouterLinkButton from '../RouterLinkButton'

const RedirectItem = styled.div`
  background-color: #f7f7f7;
  padding: 16px 24px;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const RedirectItemTitle = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 1px;
`

const RedirectItemBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RedirectItemDescription = styled.p`
  text-align: center;
`

export type ContactRedirectItemProps = {
  title: string
  description?: string
  buttonText: string
  redirectTo: string
}

const ContactRedirectItem: React.FC<ContactRedirectItemProps> = props => {
  return (
    <RedirectItem>
      <RedirectItemTitle>{props.title}</RedirectItemTitle>

      <RedirectItemBottom>
        <RedirectItemDescription>{props.description}</RedirectItemDescription>

        <RouterLinkButton to={props.redirectTo}>
          {props.buttonText}
        </RouterLinkButton>
      </RedirectItemBottom>
    </RedirectItem>
  )
}

export default ContactRedirectItem
