import { ImgixParameters, makeImgixUrl } from '@awdis/components'
import * as React from 'react'
import Helmet from 'react-helmet'

type Props = {
  username?: string
  cardType?: string
  title?: string
  description?: string
  image?: string
  imageParameters?: ImgixParameters
}

export default ({
  cardType = 'summary_large_image',
  username,
  title,
  description,
  image,
  imageParameters: { crop = '', ...imageParameters } = {},
}: Props) => {
  const mergedParameters = {
    // Crop the image to the twitter dimensions
    fit: 'crop',
    w: 800,
    h: 418, // try 1200x627
    crop: crop ? (['top'] as Array<string | number>).concat(crop) : 'top', // Default to top for cropping
    ...imageParameters,
  }

  const imgixUrl = makeImgixUrl(image, mergedParameters)

  return (
    <Helmet>
      <meta name="twitter:card" content={cardType} />
      {username && <meta name="twitter:creator" content={username} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={imgixUrl} />}
    </Helmet>
  )
}
